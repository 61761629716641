<template>
  <b-overlay :show="isFetching">
    <b-card>
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h6>
          {{ `${$t('fields.result')} (${filteredList.length})` }}
        </h6>
      </div>
      <b-table
        :items="filteredList"
        :fields="fields"
        responsive
        show-empty
      >
        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(createdAt)="data">
          {{ data.value | datetime }}
        </template>
        <template #cell(username)="data">
          <b-link :to="data.item.userId?`/players/detail/${data.item.userId}`:`/players/list`">
            {{ `${data.value}`}}
          </b-link>
        </template>
        <template #empty="">
          <p class="text-center text-muted">{{
            $t('messages.nothing_here')
          }}</p>
        </template>
      </b-table>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'RewardRedemptionHistory',
  props: {
    agentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      limit: 50,
      page: 1,
      fields: [
        '#',
        {
          key: 'createdAt',
          label: `${this.$t('fields.createdAt')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'userName',
          label: `${this.$t('user.username')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'creditReward',
          label: `${this.$t('rewards.reward_amount')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.reward.isFetchingBoxHistory,
      boxHistory: (state) => state.reward.boxHistory,
    }),
    filteredList() {
      return this.boxHistory || []
    },
  },
  watch: {
    page(val) {
      if (val) {
        this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchBoxHistory']),
    fetchData() {
      if (this.agentId) {
        this.fetchBoxHistory(this.agentId)
      }
    },
  },
}
</script>
